import React, { FC } from "react"
import Head from "next/head"
type PreloadFontsProps = {}

export const PreloadFonts: FC<PreloadFontsProps> = () => {
  return (
    <Head>
      <link
        href="/fonts/artegra-sans-bold.woff2"
        as="font"
        rel="preload"
        crossOrigin=""
      />
      <link
        href="/fonts/artegra-sans-extrabold.woff2"
        as="font"
        rel="preload"
        crossOrigin=""
      />
      <link
        href="/fonts/open-sauce-sans-regular.woff2"
        as="font"
        rel="preload"
        crossOrigin=""
      />
    </Head>
  )
}
