import { pageview } from "@lib/gtag"
import { useRouter } from "next/router"
import { FC, useEffect } from "react"

type TrackingProps = {}

export const Tracking: FC<TrackingProps> = () => {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url)
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])
  return null
}
