import React from "react"
import "../styles/reset.css"
import "../styles/font-face.css"
import "../styles/global-styles.css"
import "@config/theme/utility-styles"
import { PreloadFonts } from "@components/PreloadFonts"
import { Tracking } from "@components/Tracking"
import { Favicon } from "@components/Favicon"
import { init } from "@lib/sentry"

// Init sentry
init()

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Tracking />
      <Favicon />
      <PreloadFonts />
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
